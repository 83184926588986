import React from 'react';
import Loadable from '@components/Loadable';
import { lazy } from 'react';

const JwtLogin = Loadable(lazy(() => import('./JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('./JwtRegister')));
const ForgotPass = Loadable(lazy(() => import('./ForgotPassword')));
const Registered = Loadable(lazy(() => import('./Registered')));
const DeleteData = Loadable(lazy(() => import('./DeleteData')));

const sessionRoutes = [
  { path: '/account/signin', element: <JwtLogin /> },
  { path: '/account/signup', element: <JwtRegister /> },
  { path: '/account/registered', element: <Registered /> },
  { path: '/account/forgot-password', element: <ForgotPass /> },
  { path: '/account/delete-data', element: <DeleteData /> }
];

export default sessionRoutes;
