import React from 'react';
import { Box, Grid, List, Stack } from '@mui/material';
import { EVCCard } from './item/card';
import { ItemCharging } from './item/itemCharging';
import { useNavigate, useParams } from 'react-router-dom';
import rootApi from '@utils/baseAPI/WrapperApi';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import noDataImage from '@assets/images/customer/no_data.svg';
import Loading from '@components/Loading';

export const Nodata = ({ text }) => {
  return (
    <Box>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        <img src={noDataImage}></img>
        <p>{text}</p>
      </Stack>
    </Box>
  );
};

export const Charging = ({ customer }) => {
  const { id } = useParams();
  const { t } = useTranslation(['admin']);

  let { data, isLoading, refetch } = useQuery(['admin-detail-customer-charging'], () =>
    rootApi.get(`/admin/users/${id}/transaction`)
  );
  const charging = data?.data.data || {};

  return (
    <Box>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <EVCCard customer={customer} />
          </Grid>
          <Grid item md={8} xs={12}>
            {charging?.length ? (
              <>
                <Box style={{ maxHeight: 500, overflow: 'auto' }}>
                  <List>
                    {charging?.map((item, index) => (
                      <Box marginBottom={2} key={index}>
                        <ItemCharging key={index} item={item} />
                      </Box>
                    ))}
                  </List>
                </Box>
              </>
            ) : (
              <Nodata text={'Không có dữ liệu phiên sạc!'} />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
