import React, { useState } from 'react';
import { compose } from 'redux';
import { Breadcrumb } from '@components/index';
import { withPaginate } from '@contexts/Paginate/PaginateContext';
import ContainerBody from '@components/ContainerBody';
import ListCustomerTag from './components/ListFiles';
const ManageFiles = () => {
  return (
    <ContainerBody>
      <div className="breadcrumb">
        <Breadcrumb
          homePath={'/account/dashboard'}
          routeSegments={[{ name: 'Trang chủ', path: '/dashboard' }, { name: 'Quản lý files' }]}
        />
      </div>
      <ListCustomerTag />
    </ContainerBody>
  );
};
export default compose(withPaginate)(ManageFiles);
