import React from 'react';
import { Box, Grid, List, Paper } from '@mui/material';
import { EVCCard } from './item/card';
import { useNavigate, useParams } from 'react-router-dom';
import rootApi from '@utils/baseAPI/WrapperApi';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Nodata } from './charging';
import { ItemDeposit } from './item/itemDeposit';
import Loading from '@components/Loading';

export const Deposit = ({ customer }) => {
  const { id } = useParams();
  const { t } = useTranslation(['admin']);

  let { data, isLoading, refetch } = useQuery(['admin-detail-customer-deposit'], () =>
    rootApi.get(`/admin/users/${id}/deposit`)
  );
  const deposit = data?.data.data || {};

  return (
    <Box>
      {isLoading ? (
        <Loading />
      ) : (
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <EVCCard customer={customer} />
          </Grid>
          <Grid item md={8} xs={12}>
            {deposit?.length ? (
              <>
                <Box style={{ maxHeight: 500, overflow: 'auto' }}>
                  <List>
                    {deposit?.map((item, index) => (
                      <Box marginBottom={2} key={index}>
                        <ItemDeposit key={index} item={item} />
                      </Box>
                    ))}
                  </List>
                </Box>
              </>
            ) : (
              <>
                <Nodata text={'Không có dữ liệu nạp tiền!'} />
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
