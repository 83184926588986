import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { deepOrange } from '@material-ui/core/colors';
import { Avatar, Button, Divider, Grid } from '@mui/material';
import moment from 'moment';
import coverImage from '@assets/images/customer/cover.png';
import avatarImage from '@assets/images/customer/avatar.png';
import { useMutation } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import { showError, showSuccess } from '@components/notification';
import { apiConfirmCustomer, apiRejectCustomer } from '@services/customer.service';

export const Personal = ({ customer }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false); // State để quản lý trạng thái loading

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const res = await apiConfirmCustomer(customer?.id);
      console.log('🚀 ~ handleConfirm ~ res:', res);
      if (res?.data.status.code !== 'success') {
        showSuccess({ message: 'Xác thực thành công' });
      }
    } catch (error) {
      showError({ message: 'Xác thực thất bại' });
      console.error('Error translating description:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleReject = async () => {
    setLoading(true);
    try {
      const res = await apiRejectCustomer(customer?.id);
      console.log('🚀 ~ handleReject ~ res:', res);
      if (res?.data.status.code === 'success') {
        showSuccess({ message: 'Từ chối  thành công' });
      }
    } catch (error) {
      showError({ message: 'Từ chối thất bại' });
      console.error('Error translating description:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      {/* Cover Image */}
      <div className={classes.cover}>
        <img src={coverImage} alt="Cover" className={classes.coverImage} />
      </div>

      {/* Avatar and Basic Info */}
      <Grid container className={classes.infoContainer}>
        <Grid item xs={12} sm={2}>
          <Avatar alt="User Avatar" src={avatarImage} className={classes.avatar} />
        </Grid>
        <Grid item xs={12} sm={10} className={classes.basicInfo}>
          <h2 className={classes.fullName}>{customer?.full_name ?? 'Không xác định'}</h2>
          <span className={classes.email}>{customer?.email ?? 'Không xác định'}</span>
        </Grid>
      </Grid>

      {/* Personal Information Section */}
      <Divider className={classes.divider} />
      <h2 className={classes.sectionTitle}>Thông tin cá nhân</h2>
      <Grid container spacing={2} className={classes.detailsContainer}>
        <Grid item xs={12} sm={6}>
          <TextView label="Tên đăng nhập" value={customer?.username ?? '-'} />
          <TextView label="Giới tính" value={customer?.gender === 'MALE' ? 'Nam' : 'Nữ' ?? '-'} />
          <TextView
            label="Thời gian tạo"
            value={
              customer?.created_date
                ? moment(customer.created_date).format('DD-MM-YYYY HH:mm')
                : '-'
            }
          />
          <div className={classes.cccdImages}>
            <h3 className={classes.label}>Ảnh CCCD</h3>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className={classes.imageContainer}>
                  <img
                    src={customer?.fornt_cccd ?? '/placeholder.png'}
                    alt="Ảnh CCCD mặt trước"
                    className={classes.cccdImage}
                  />
                  <span className={classes.imageLabel}>Mặt trước</span>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.imageContainer}>
                  <img
                    src={customer?.behind_cccd ?? '/placeholder.png'}
                    alt="Ảnh CCCD mặt sau"
                    className={classes.cccdImage}
                  />
                  <span className={classes.imageLabel}>Mặt sau</span>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextView
            label="Ngày sinh"
            value={
              customer?.date_of_birth ? moment(customer.date_of_birth).format('DD-MM-YYYY') : '-'
            }
          />
          <TextView label="Địa chỉ" value={customer?.address ?? '-'} />
          <div className={classes.status}>
            <span className={classes.label}>Trạng thái</span>
            {customer?.confirm_cccd_status === 'PENDING' ? (
              <div className={classes.statusButtonsContainer}>
                <div className={classes.statusButtons}>
                  <h2 className={classes.pendingText}>Chờ xác thực</h2>
                  <Button
                    variant="outlined"
                    className={classes.confirmButton}
                    onClick={handleConfirm}>
                    Xác thực
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.rejectButton}
                    onClick={handleReject}>
                    Từ chối
                  </Button>
                </div>
              </div>
            ) : customer?.confirm_cccd_status === 'APPROVED' ? (
              'Đã xác thực'
            ) : customer?.confirm_cccd_status === 'REJECTED' ? (
              'Từ chối'
            ) : customer?.confirm_cccd_status === 'NONE' ? (
              'Chưa xác thực'
            ) : (
              ''
            )}
          </div>
          <div className={classes.portraitSection}>
            <h3 className={classes.label}>Ảnh chân dung</h3>
            <div className={classes.portraitContainer}>
              <img
                src={customer?.portrait_image ?? '/placeholder.png'}
                alt="Ảnh chân dung"
                className={classes.portraitImage}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

// TextView Component
const TextView = ({ label, value }) => (
  <div style={{ marginBottom: 16 }}>
    <span style={{ fontSize: '12px', fontWeight: 400 }}>{label}</span>
    <br />
    <span style={{ fontSize: '14px', fontWeight: 500 }}>{value}</span>
  </div>
);

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '16px'
  },
  cover: {
    position: 'relative',
    marginBottom: '24px'
  },
  coverImage: {
    width: '100%',
    height: '128px',
    objectFit: 'cover',
    borderRadius: '8px'
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginTop: '-64px',
    border: `4px solid ${theme.palette.background.paper}`
  },
  infoContainer: {
    marginBottom: '24px'
  },
  basicInfo: {
    paddingLeft: '16px',
    alignSelf: 'center'
  },
  fullName: {
    fontSize: '18px',
    fontWeight: 700,
    margin: 0
  },
  email: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  divider: {
    margin: '24px 0'
  },
  sectionTitle: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '16px'
  },
  detailsContainer: {
    marginTop: '16px'
  },
  label: {
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: '8px',
    display: 'block'
  },
  status: {
    marginBottom: '16px'
  },
  confirmButton: {
    padding: '4px 8px',
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
    '&:hover': {
      background: 'var(--Primary-Basic, #2D9764)', // Green color on hover
      borderColor: '#28a745',
      color: '#fff' // Optional, matching border color
    }
  },
  rejectButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    padding: '4px 8px',
    '&:hover': {
      background: 'var(--Primary-Basic, #2D9764)', // Green color on hover
      borderColor: '#28a745',
      color: '#fff' // Optional, matching border color
    }
  },
  statusButtonsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
    justifyContent: 'space-between'
  },
  statusButtons: {
    display: 'flex',
    gap: '8px' // Space between buttons
  },
  pendingText: {
    color: 'var(--Neutral-900, #18181B)',
    // fontFamily: 'Inter Tight',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px' /* 142.857% */
  },
  cccdImages: {
    marginTop: '16px',
    marginBottom: '16px'
  },
  imageContainer: {
    textAlign: 'center',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '8px',
    backgroundColor: '#f9f9f9'
  },
  cccdImage: {
    width: '100%',
    height: '200px', // Adjust as needed
    objectFit: 'cover',
    borderRadius: '8px'
  },
  imageLabel: {
    marginTop: '8px',
    fontSize: '14px',
    fontWeight: 500,
    display: 'block'
  },
  portraitSection: {
    marginTop: '16px',
    textAlign: 'left'
  },
  portraitContainer: {
    display: 'inline-block',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '8px',
    backgroundColor: '#f9f9f9'
  },
  portraitImage: {
    width: '150px', // Adjust the size as necessary
    height: '200px',
    objectFit: 'cover',
    borderRadius: '8px'
  }
}));

export default Personal;
