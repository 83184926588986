import React from 'react';
import { Avatar } from '@material-ui/core';
import { Box, Divider, Grid, Typography, Card, Stack, CardContent } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { formatCurrency } from '@components/helper';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

export const ItemDeposit = ({ item }) => {
  return (
    <Box sx={{ border: 1, borderColor: '#E4E4E7', borderRadius: 2 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        padding={2}>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Avatar style={{ backgroundColor: '#EAF5EF', height: 48, width: 48 }}>
            <QrCodeScannerIcon sx={{ height: 24, width: 24, color: '#2D9764' }} />
          </Avatar>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
            <Typography
              variant="span"
              component="span"
              style={{ fontSize: '16px', fontWeight: 500 }}>
              Nạp điểm
            </Typography>
            <Typography
              variant="span"
              component="span"
              style={{ fontSize: '14px', fontWeight: 400 }}>
              {moment(item?.created_timestamp * 1000).format('DD-MM-YYYY HH:mm')}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="column" justifyContent="flex-end" alignItems="center">
          <Typography
            variant="span"
            component="span"
            style={{ fontSize: '16px', fontWeight: 500, color: '#2D9764' }}>
            + {formatCurrency(item?.amount || 0)} EVC
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
