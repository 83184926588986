import React, { useEffect, useState } from 'react';
import { Box, Chip, Grid, Stack, Tab, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import EvStationIcon from '@mui/icons-material/EvStation';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Chart from '@components/charts/new-charts/Chart';
import { COLORS, COLOR_1, COLOR_2, COLOR_3 } from '@components/charts/new-charts/chart.constant';
import { CardInfo } from './components/CardInfo';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  convertLocaltimeToUTC,
  formatCurrency,
  generateDateTimeArray,
  getPast30Days
} from '@components/helper';
import { useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import SelectBox from '@components/SelectBox';
import { LinearProcessBarCustom } from './components/LinearProcessBarCustom';
import moment from 'moment';
import { addCommas } from '@utils/urls';
import Loading from '@components/Loading';

const Dashboard = () => {
  const partner_id = 1;
  const [partner, setPartner] = useState(null);
  const { data: partners, isLoading: isLoadingPartner } = useQuery(['admin-get-all-partners'], () =>
    rootApi.get('/admin/partners/search?page=0&size=99999')
  );
  const tmpPartners = partners?.data.data || [];

  const partnerOptions = tmpPartners
    ? tmpPartners.map((d) => ({ value: String(d.id), label: String(d.name) }))
    : [];
  const {
    data: dataTrending,
    isLoading: loadingTrending,
    refetch: refetchTrending
  } = useQuery(['admin-get-trending-48Hrs', partner_id], () =>
    rootApi.get(`/dashboards/charging-trend`, { params: { partner_id: partner?.value } })
  );
  const dataChargingTrend = [
    {
      name: 'Số kw sạc hôm qua',
      data: dataTrending?.data?.data?.yesterday?.map((item) => (item / 1000)?.toFixed(1)) ?? [],
      type: 'area',
      color: '#296BCC'
    },
    {
      name: 'Số kw sạc hôm nay',
      data: dataTrending?.data?.data?.today?.map((item) => (item / 1000)?.toFixed(1)) ?? [],
      type: 'area',
      color: '#2D9764'
    }
  ];
  const {
    data: dataPower,
    isLoading: loadingPower,
    refetch: refetchPower
  } = useQuery(['admin-get-power'], () =>
    rootApi.get(
      `/dashboards/get-power-date?date=${convertLocaltimeToUTC(new Date(), '00:00:00.000')}`,
      {
        params: { partner_id: partner?.value }
      }
    )
  );
  const {
    data: dataChargingSession,
    isLoading: loadingChargingSession,
    refetch: refetchChargingSession
  } = useQuery(['admin-get-charging-session'], () =>
    rootApi.get(
      `/dashboards/transaction-info?date=${convertLocaltimeToUTC(new Date(), '00:00:00.000')}`,
      {
        params: { partner_id: partner?.value }
      }
    )
  );
  const {
    data: dataIncome,
    isLoading: loadingIncome,
    refetch: refetchIncome
  } = useQuery(['admin-get-income'], () =>
    rootApi.get(
      `/dashboards/total-revenue?date=${convertLocaltimeToUTC(new Date(), '00:00:00.000')}`,
      {
        params: { partner_id: partner?.value }
      }
    )
  );
  const {
    data: dataCustomer,
    isLoading: loadingCustomer,
    refetch: refetchCustomer
  } = useQuery(['admin-get-customer', partner_id], () =>
    rootApi.get(`/dashboards/user-info`, { params: { partner_id: partner?.value } })
  );

  const {
    data: dataStationInfo,
    isLoading: loadingStationInfo,
    refetch: refetchStationInfo
  } = useQuery(['admin-get-station-info', partner_id], () =>
    rootApi.get(`/dashboards/station-info`, { params: { partner_id: partner?.value } })
  );

  const {
    data: dataDepotTrend,
    isLoading: loadingDepotTrend,
    refetch: refetchDepotTrend
  } = useQuery(['admin-get-depots/charging-trend', partner_id], () =>
    rootApi.get(
      `/dashboards/depots/charging-trend?date=${convertLocaltimeToUTC(new Date(), '00:00:00.000')}`,
      { params: { partner_id: partner?.value } }
    )
  );
  let sortedDataDepotTrend = dataDepotTrend?.data?.data;
  sortedDataDepotTrend = sortedDataDepotTrend?.sort((a, b) => b.power_of_today - a.power_of_today);

  const lstValueDepotTrendYesterday = sortedDataDepotTrend?.map((item) =>
    (item?.power_of_yesterday / 1000)?.toFixed(1)
  );
  const lstValueDepotTrendToday = sortedDataDepotTrend?.map((item) =>
    (item?.power_of_today / 1000).toFixed(1)
  );
  const lstValueDepotTrendName = sortedDataDepotTrend?.map((item) => item?.depot_code);

  const lstValueDepotTrend = [
    {
      name: 'Số kw sạc hôm qua',
      data: lstValueDepotTrendYesterday ?? [],
      color: '#296BCC'
    },
    {
      name: 'Số kw sạc hôm nay',
      data: lstValueDepotTrendToday ?? [],
      color: '#2D9764'
    }
  ];
  const fiftyDayAgo = new Date();
  fiftyDayAgo.setDate(new Date().getDate() - 14);
  const currentDate = new Date();
  currentDate.setDate(new Date().getDate() + 1);

  const convertDataIncome = (originalObject) => {
    if (!originalObject) {
      return;
    }
    const data = originalObject;
    const timestamps = Object.keys(data).sort();
    const result = {
      timestamps: [],
      total: [],
      charging_fee: [],
      idle_fee: [],
      parking_fee: []
    };

    timestamps.forEach((timestamp) => {
      const entry = data[timestamp];
      result.timestamps.push(moment(timestamp)?.format('DD/MM'));
      result.total.push(entry.total);
      result.charging_fee.push(entry.charging_fee);
      result.idle_fee.push(entry.idle_fee);
      result.parking_fee.push(entry.parking_fee);
    });
    return result;
  };

  const {
    data: dataTotalRevenue,
    isLoading: loadingTotalRevenue,
    refetch: refetchTotalRevenue
  } = useQuery(['admin-get-total-revenue-by-day', partner_id], () =>
    rootApi.get(
      `/dashboards/total-revenue/by-day?from_date=${convertLocaltimeToUTC(
        fiftyDayAgo,
        '00:00:00.000'
      )}&to_date=${convertLocaltimeToUTC(currentDate, '00:00:00.000')}`,
      { params: { partner_id: partner?.value } }
    )
  );
  const dataIncomeConvertedOrigin = convertDataIncome(dataTotalRevenue?.data?.data);

  const dataIncomeChart = [
    {
      name: 'Tổng tiền',
      data: dataIncomeConvertedOrigin?.total,
      type: 'column',
      color: '#2D9764'
    },
    {
      name: 'Phí đỗ xe',
      data: dataIncomeConvertedOrigin?.parking_fee,
      type: 'line',
      color: '#29B1CC'
    },
    {
      name: 'Phí sạc',
      data: dataIncomeConvertedOrigin?.charging_fee,
      type: 'column',
      color: '#296BCC'
    },
    {
      name: 'Phí đỗ lâu',
      data: dataIncomeConvertedOrigin?.idle_fee,
      type: 'line',
      color: '#F29913'
    }
  ];
  // Deposit
  const {
    data: dataDeposit,
    isLoading: loadingDeposit,
    refetch: refetchDeposit
  } = useQuery(['dashboards/deposit'], () => rootApi.get(`/dashboards/deposit`));

  // time busy used
  const lstPast30Days = getPast30Days();
  const {
    data: dataTimeUsed,
    isLoading: loadingTimeUsed,
    refetch: refetchTimeUsed
  } = useQuery(['admin-get-time-used', partner_id], () =>
    rootApi.get(`/dashboards/stations/level-of-busyness`, {
      params: { partner_id: partner?.value }
    })
  );

  const lstDataLevelOfBusyness = [
    {
      name: 'Tỷ lệ sạc',
      data: dataTimeUsed?.data?.data?.level_of_busyness,
      type: 'area',
      color: '#2D9764'
    }
  ];

  const [valueTabTrending, setValueTabTrending] = React.useState('48HrsTrending');
  const [valueTabRating, setValueTabRating] = React.useState('ratingStation');

  const handleChange = (event, newValue) => {
    setValueTabTrending(newValue);
  };
  const handleChangeRating = (event, newValue) => {
    setValueTabRating(newValue);
  };
  useEffect(() => {
    refetchTrending();
    refetchChargingSession();
    refetchPower();
    refetchCustomer();
    refetchStationInfo();
    refetchTotalRevenue();
    refetchDepotTrend();
    refetchDeposit();
    refetchTimeUsed();
    refetchIncome();
  }, [partner]);

  const chargingStationPercent = dataStationInfo?.data?.data?.number_of_station
    ? Math.round(
        (dataStationInfo?.data?.data?.number_of_charging_station * 100) /
          dataStationInfo?.data?.data?.number_of_station
      )
    : 0;
  const availableStationPercent = dataStationInfo?.data?.data?.number_of_station
    ? Math.round(
        (dataStationInfo?.data?.data?.number_of_available_station * 100) /
          dataStationInfo?.data?.data?.number_of_station
      )
    : 0;
  const errorStationPercent = dataStationInfo?.data?.data?.number_of_station
    ? Math.round(
        (dataStationInfo?.data?.data?.number_of_error_station * 100) /
          dataStationInfo?.data?.data?.number_of_station
      )
    : 0;
  return (
    <>
      <Box width="100%" overflow="auto" padding={2}>
        <Grid container spacing={2} padding={1}>
          <Grid item xs={12}>
            <SelectBox
              value={partner}
              onChange={(_, option) => setPartner(option)}
              options={partnerOptions}
              label="Chọn đối tác"
              name="partner_id"
              size="small"
              col={3}
              style={{ marginTop: '16px' }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={2.4} style={{ display: 'flex', flexDirection: 'column' }}>
            <CardInfo
              icon={<EvStationIcon style={{ fontSize: 32, color: '#2D9764' }} />}
              title={'Điện tiêu thụ'}
              value={`${(dataPower?.data?.data / 1000).toFixed(0)} KW`}
              label_1="Cao điểm"
              value_1="0.00"
              label_2="Thấp điểm"
              value_2="0.00"
              loading={loadingPower}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={2.4} style={{ display: 'flex', flexDirection: 'column' }}>
            <CardInfo
              icon={<SettingsSuggestIcon style={{ fontSize: 32, color: '#2D9764' }} />}
              title={'Phiên sạc'}
              value={
                dataChargingSession?.data?.data?.number_of_normal_transaction +
                dataChargingSession?.data?.data?.number_of_not_normal_transaction +
                dataChargingSession?.data?.data?.number_of_cancelled_transaction
              }
              label_1="Bình thường"
              value_1={dataChargingSession?.data?.data?.number_of_normal_transaction}
              label_2="Không hợp lệ"
              value_2={dataChargingSession?.data?.data?.number_of_not_normal_transaction}
              label_3="Bị huỷ"
              value_3={dataChargingSession?.data?.data?.number_of_cancelled_transaction}
              loading={loadingChargingSession}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={2.4} style={{ display: 'flex', flexDirection: 'column' }}>
            <CardInfo
              icon={<MonetizationOnIcon style={{ fontSize: 32, color: '#2D9764' }} />}
              title={'Doanh thu'}
              value={`${formatCurrency(dataIncome?.data?.data?.total)} đ`}
              label_1="Phí sạc"
              value_1={`${formatCurrency(dataIncome?.data?.data?.charging_fee)} đ`}
              label_2="Phí đỗ xe"
              value_2={`${formatCurrency(dataIncome?.data?.data?.parking_fee)} đ`}
              label_3="Phí đỗ lâu"
              value_3={`${formatCurrency(dataIncome?.data?.data?.idle_fee)} đ`}
              loading={loadingIncome}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={2.4} style={{ display: 'flex', flexDirection: 'column' }}>
            <CardInfo
              icon={<AccountBalanceWalletIcon style={{ fontSize: 32, color: '#2D9764' }} />}
              title={'Tiền khách nạp hôm nay'}
              value={`${formatCurrency(dataDeposit?.data?.data?.today)} đ`}
              label_1="Tháng này"
              value_1={`${formatCurrency(dataDeposit?.data?.data?.cur_month)} đ`}
              label_2="Tháng trước"
              value_2={`${formatCurrency(dataDeposit?.data?.data?.pre_month)} đ`}
              loading={loadingCustomer}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={2.4} style={{ display: 'flex', flexDirection: 'column' }}>
            <CardInfo
              icon={<PeopleAltIcon style={{ fontSize: 32, color: '#2D9764' }} />}
              title={'Khách hàng'}
              value={dataCustomer?.data?.data?.number_activate_user}
              label_1="Đăng ký hôm nay"
              value_1={dataCustomer?.data?.data?.register_user_of_today}
              label_2="Sạc hôm nay"
              value_2={dataCustomer?.data?.data?.number_user_charging_today}
              loading={loadingCustomer}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={'center'} padding={1}>
          <Grid item xs={12} lg={8} xl={8}>
            <Card sx={{ height: 100 + '%' }}>
              <CardContent>
                {loadingTotalRevenue ? (
                  <Loading />
                ) : (
                  <>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      fontWeight={700}
                      fontSize={18}>
                      Thu nhập
                    </Typography>
                    <Chart
                      options={{
                        chart: {
                          toolbar: {
                            show: false
                          }
                        },
                        plotOptions: {
                          bar: {
                            horizontal: false,
                            // columnWidth: '55%',
                            borderRadius: 4
                          }
                        },
                        colors: COLORS,
                        dataLabels: {
                          enabled: false,
                          style: {
                            fontSize: '12px',
                            colors: ['#fff']
                          }
                        },
                        stroke: {
                          show: true,
                          width: 3,
                          colors: ['#fff']
                        },
                        xaxis: {
                          categories: dataIncomeConvertedOrigin?.timestamps
                        },
                        yaxis: {
                          labels: {
                            formatter: (val) => formatCurrency(val)
                          }
                        },

                        fill: {
                          opacity: 1
                        },
                        tooltip: {
                          y: {
                            formatter: (val) => `${formatCurrency(val)} VND`
                          }
                        }
                      }}
                      series={dataIncomeChart}
                      height={350}
                    />
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4} xl={4}>
            <Card sx={{ height: 100 + '%' }}>
              <CardContent>
                {loadingDepotTrend ? (
                  <Loading />
                ) : (
                  <>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      fontWeight={700}
                      fontSize={18}>
                      Xếp hạng trạm sạc
                    </Typography>
                    <Chart
                      options={{
                        chart: {
                          toolbar: {
                            show: false
                          }
                        },
                        plotOptions: {
                          bar: {
                            horizontal: true,
                            dataLabels: {
                              position: 'top'
                            }
                          }
                        },
                        colors: COLORS,
                        dataLabels: {
                          enabled: true,
                          offsetX: -6,
                          style: {
                            fontSize: '12px',
                            colors: ['#fff']
                          }
                        },
                        stroke: {
                          show: true,
                          width: 1,
                          colors: ['#fff']
                        },
                        xaxis: {
                          categories: lstValueDepotTrendName ?? []
                        },
                        yaxis: {
                          labels: {
                            style: {
                              fontSize: '10px',
                              colors: COLORS
                            }
                          }
                        },
                        tooltip: {
                          y: {
                            formatter: (val) => `${formatCurrency(val, 1)} KW`
                          }
                        }
                      }}
                      series={lstValueDepotTrend}
                      height={350}
                      type="bar"
                    />
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2} padding={1}>
          <Grid item xs={12} lg={8} xl={8}>
            <Card sx={{ height: 100 + '%' }}>
              <CardContent>
                <TabContext value={valueTabTrending}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      <Tab label="Xu hướng sạc 48 giờ" value="48HrsTrending" />
                      <Tab label="Thời gian sử dụng" value="timeUsed" />
                    </TabList>
                  </Box>
                  <TabPanel value="48HrsTrending">
                    {loadingTrending ? (
                      <Loading></Loading>
                    ) : (
                      <Chart
                        options={{
                          chart: {
                            zoom: {
                              enabled: false
                            },
                            toolbar: {
                              show: false
                            }
                          },
                          colors: [COLOR_1],
                          fill: {
                            type: 'gradient',
                            gradient: {
                              shadeIntensity: 1,
                              opacityFrom: 0.7,
                              opacityTo: 0.9,
                              stops: [0, 80, 100]
                            }
                          },
                          dataLabels: {
                            enabled: false
                          },
                          stroke: {
                            curve: 'smooth',
                            width: 3
                          },
                          labels: [
                            '00:00',
                            '01:00',
                            '02:00',
                            '03:00',
                            '04:00',
                            '05:00',
                            '06:00',
                            '07:00',
                            '08:00',
                            '09:00',
                            '10:00',
                            '11:00',
                            '12:00',
                            '13:00',
                            '14:00',
                            '15:00',
                            '16:00',
                            '17:00',
                            '18:00',
                            '19:00',
                            '20:00',
                            '21:00',
                            '22:00',
                            '23:00'
                          ],
                          yaxis: {
                            opposite: true
                          },
                          tooltip: {
                            y: {
                              formatter: (val) => `${val} KW`
                            }
                          },
                          legend: {
                            horizontalAlign: 'left'
                          }
                        }}
                        type="area"
                        series={dataChargingTrend}
                        height={180}
                      />
                    )}
                  </TabPanel>
                  <TabPanel value="timeUsed">
                    {loadingTimeUsed ? (
                      <Loading></Loading>
                    ) : (
                      <Chart
                        options={{
                          chart: {
                            zoom: {
                              enabled: false
                            },
                            toolbar: {
                              show: false
                            }
                          },
                          colors: [COLOR_1],
                          fill: {
                            type: 'gradient',
                            gradient: {
                              shadeIntensity: 1,
                              opacityFrom: 0.7,
                              opacityTo: 0.9,
                              stops: [0, 80, 100]
                            }
                          },
                          dataLabels: {
                            enabled: false
                          },
                          stroke: {
                            curve: 'smooth',
                            width: 3
                          },
                          labels: lstPast30Days,
                          yaxis: {
                            opposite: true
                          },
                          tooltip: {
                            y: {
                              formatter: (val) => `${val} %`
                            }
                          },
                          legend: {
                            horizontalAlign: 'left'
                          }
                        }}
                        type="area"
                        series={lstDataLevelOfBusyness}
                        height={200}
                      />
                    )}
                  </TabPanel>
                </TabContext>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4} xl={4}>
            <Card sx={{ height: 100 + '%' }}>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  fontWeight={700}
                  fontSize={18}>
                  Giám sát thời gian thực
                </Typography>
                <Stack direction={'column'} spacing={2}>
                  <LinearProcessBarCustom
                    label={'Trụ đang sạc'}
                    value={chargingStationPercent}
                    loading={loadingStationInfo}
                    value_1={dataStationInfo?.data?.data?.number_of_station}
                    value_2={dataStationInfo?.data?.data?.number_of_charging_station}
                    color={'#2D9764'}
                  />
                  <LinearProcessBarCustom
                    label={'Trụ đang rảnh'}
                    value={availableStationPercent}
                    loading={loadingStationInfo}
                    value_1={dataStationInfo?.data?.data?.number_of_station}
                    value_2={dataStationInfo?.data?.data?.number_of_available_station}
                    color={'#F29913'}
                  />
                  <LinearProcessBarCustom
                    label={'Trụ lỗi'}
                    value={errorStationPercent}
                    loading={loadingStationInfo}
                    value_1={dataStationInfo?.data?.data?.number_of_station}
                    value_2={dataStationInfo?.data?.data?.number_of_error_station}
                    color={'#FA5050'}
                  />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
