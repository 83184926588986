import { DEFAULT_LANG, LANG_OPTIONS } from '@contants/index';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enAdmin from '@assets/locales/en/admin.json';
import viAdmin from '@assets/locales/vi/admin.json';

const resources = {
  [LANG_OPTIONS.VI]: {
    admin: viAdmin
  },
  [LANG_OPTIONS.EN]: {
    admin: enAdmin
  }
};
const getCurrentLang = () => {
  let language = window.localStorage.getItem('language');

  if (Object.values(LANG_OPTIONS).every((lang) => lang !== language)) {
    window.localStorage.setItem('language', DEFAULT_LANG);
    language = DEFAULT_LANG;
  }

  return language;
};
i18n.use(initReactI18next).init({
  resources: resources,
  lng: getCurrentLang(),
  fallbackLng: DEFAULT_LANG,
  ns: ['admin'],
  defaultNS: ['admin'],
  interpolation: {
    escapeValue: false
  },
  debug: false,
  react: {
    useSuspense: true,
    wait: true
  }
});

export default i18n;
