import React from 'react';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

function SelectBox(props) {
  const {
    name,
    label,
    required,
    col,
    options,
    loading,
    multiple,
    hasError = false,
    errorText,
    value,
    onChange,
    disabled
  } = props;

  const _label = (
    <>
      {label} {required && <span style={{ color: 'red' }}>(*)</span>}
    </>
  );

  return (
    <Grid item md={col || 4} sm={12} sx={{ mt: 1 }}>
      <Autocomplete
        disabled={disabled}
        {...props}
        multiple={multiple}
        value={value}
        onChange={onChange}
        options={options}
        loading={loading}
        isOptionEqualToValue={(option, value) => option?.label === value?.label}
        getOptionLabel={(option) => option.label || ''}
        getOptionValue={(option) => option.value || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
            error={hasError}
            helperText={errorText}
            label={_label}
            variant="outlined"
            fullWidth
          />
        )}
      />
    </Grid>
  );
}

export default SelectBox;
