import { contantAuthentication, SOCKET_EVENTS } from '@contants/index';
import { SOCKET_HOST } from '@configs/index';
import React, { useEffect, useState, createContext } from 'react';
import { loadFromLocalStorageObjectFromBase64, saveToLocalStorage } from '@databases/localStorage';
import io from 'socket.io-client';
import { isEmpty } from 'lodash';
import Alert from '@mui/lab/Alert';

export const SocketContext = createContext({});

const host = SOCKET_HOST;

export const SocketProvider = ({ children }) => {
  const [itemSocket, setItemSocket] = useState(null);
  const [notiSocket, setNotiSocket] = useState(null);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');

  let user = loadFromLocalStorageObjectFromBase64(contantAuthentication.DATA_AUTH);

  if (!isEmpty(user)) {
    user = JSON.parse(user);
  }
  // const { actions } = useNotification();

  useEffect(() => {
    // const accessToken = user?.access_token;
    const accessToken =
      'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyIiwiaWF0IjoxNjg4MzE0ODg2LCJleHAiOjE3NzQ3MTQ4ODZ9.j37N7sN8lQ9JOz9CE4W6381pGv4vP_yoE4zoZnmVXPQ';
    console.log(accessToken);
    if (!accessToken) return;
    //Todo: 20230716: TienMH tam comment de ko goi socket

    // const notiSck = io(`${host}`);
    // notiSck.emit('authenticate', {
    //   token: accessToken
    // });
    //
    // setNotiSocket(notiSck);
    //
    // const receivingEvent = 'charge_block';
    // notiSck.on(receivingEvent, (res) => {
    //   console.log('------- Nhan Socket ---------');
    //   setAlert(true);
    //   setAlertContent(res);
    //   console.log(res);
    // });
    //
    // return () => {
    //   notiSck?.disconnect();
    // };
    //end Todo: 20230716: TienMH tam comment de ko goi socket
  }, [user?.access_token]);

  const value = {
    itemSocket,
    notiSocket
  };
  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>;
};
export default SocketContext;
