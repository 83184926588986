import React from 'react';
import { Icon, IconButton, Tooltip } from '@mui/material';

const ActViewDetail = ({ tag, setTag, setOpenView }) => {
  const handleOpenDialog = () => {
    setOpenView(true);
    setTag(tag);
  };
  return (
    <Tooltip title="Chi tiết" placement="top">
      <IconButton className="button" aria-label="Chi tiết" onClick={handleOpenDialog}>
        <Icon>remove_red_eye</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default ActViewDetail;
