import React from 'react';
import { Icon, IconButton, Tooltip } from '@mui/material';

const ActDeleteCustomer = ({ tag, setTag, setOpenDelete }) => {
  const handleOpenDialog = () => {
    setOpenDelete(true);
    setTag(tag);
  };
  return (
    <>
      <Tooltip title="Xóa file" placement="top">
        <IconButton className="button" aria-label="Xóa" onClick={handleOpenDialog}>
          <Icon>delete_outline</Icon>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ActDeleteCustomer;
