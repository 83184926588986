import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default ({ error, loading }) => {
  if (loading)
    return (
      <Container maxWidth="md">
        <Box display="flex" justifyContent="space-around">
          <CircularProgress size={30} />
        </Box>
      </Container>
    );
  if (error) {
    return (
      <Container maxWidth="md">
        <Alert severity="error">
          <AlertTitle>Có lỗi xảy ra!</AlertTitle>
          Bạn vui lòng liên hệ Admin để được hỗ trợ
        </Alert>
      </Container>
    );
  }
  return <div />;
};
