import React, { useState } from 'react';
import { useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from '@components/FormControls/InputField';
import DialogFormField from '@components/FormControls/DialogFormField';
import { useMutation, useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import { showError, showSuccess, showWarning } from '@components/notification';
import * as Yup from 'yup';
import SelectBox from '@components/SelectBox';
import { Box, IconButton, Input, LinearProgress, Typography } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { uploadFile } from '@utils/utils';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const DialogsEdit = (props) => {
  const { tag, open, setOpen, refetch } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [processUpload, setProcessUpload] = useState(0);
  const [onUpload, setOnUpload] = useState(false);

  const handleFileChange = async (event) => {
    try {
      setOnUpload(true);
      const file = event.target.files[0];
      setSelectedFile(file);
      setFileName(file?.name);
      formik.setFieldValue('filename', file?.name, false);
      const res = await uploadFile(file, (number) => setProcessUpload(number));
      console.log(res?.data?.data);
      formik.setFieldValue('url', res?.data?.data, false);
      showSuccess({ message: 'Tải lên thành công!' });
    } catch (e) {
      showError('Không thể tải lên file!');
    } finally {
      setOnUpload(false);
    }
  };

  React.useEffect(() => {
    if (open) {
      formik.setFieldValue('filename', tag?.filename, false);
      formik.setFieldValue('description', tag?.description, false);
      formik.setFieldValue('url', tag?.url, false);
      formik.setFieldValue('vendor', tag?.vendor, false);
      formik.setFieldValue('version', tag?.version, false);
      setSelectedFile('');
      setProcessUpload('');
    }
  }, [tag?.id, open]);

  const { mutateAsync, isLoading, isSuccess } = useMutation('update-file-upload', (data) => {
    return rootApi.put(`/files/update/${tag?.id}`, {
      filename: data.filename,
      description: data?.description,
      url: data?.url,
      vendor: data?.vendor,
      version: data?.version
    });
  });
  const formik = useFormik({
    initialValues: {
      filename: tag?.filename ?? '',
      description: tag?.description ?? '',
      url: tag?.url,
      vendor: tag?.vendor,
      version: tag?.version
    },
    validationSchema: Yup.object({
      filename: Yup.string().required().label('Tên file').nullable()
    }),
    onSubmit: (values) => {
      if (!values?.url) {
        showWarning({ message: 'Vui lòng chọn file!' });
        return;
      }
      mutateAsync(values)
        .then(() => {
          handleClose();
          showSuccess({ message: 'Cập nhật file thành công' });
          formik.resetForm();
        })
        .catch((e) => {
          return showError('Cập nhật thất bại!');
        });
    }
  });
  const handleClose = () => {
    refetch();
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Chỉnh sửa thông tin</DialogTitle>
        <DialogContent>
          <DialogFormField
            onCloseDialog={handleClose}
            formik={formik}
            isLoading={isLoading}
            isSuccess={isSuccess}
            actionName="Chỉnh sửa">
            <Input
              type="file"
              onChange={handleFileChange}
              sx={{ width: '100%', marginTop: '5px' }}
              style={{ display: 'none' }}
              id="upload-button"
            />
            <label htmlFor="upload-button">
              <IconButton color="primary" aria-label="upload picture" component="span">
                <CloudUpload />
              </IconButton>
              <Typography variant="body1" component="span">
                {selectedFile ? selectedFile.name : 'Tải lên file mới'}
              </Typography>
            </label>
            <Box sx={{ width: '100%' }}>
              <>{processUpload != 0 && <LinearProgressWithLabel value={processUpload} />}</>
            </Box>
            <InputField
              formik={formik}
              name="filename"
              value={formik.values.filename}
              onBlur={formik.handleBlur}
              error={formik.touched.filename && Boolean(formik.errors.filename)}
              helperText={formik.touched.filename && formik.errors.filename}
              required
              label="Tên file"
              col={12}
            />
            <InputField
              formik={formik}
              name="vendor"
              value={formik.values.vendor}
              onBlur={formik.handleBlur}
              label="Nhà cung cấp"
              col={12}
            />
            <InputField
              formik={formik}
              name="version"
              value={formik.values.version}
              onBlur={formik.handleBlur}
              label="Phiên bản"
              col={12}
            />
            <InputField
              formik={formik}
              name="description"
              value={formik.values.description}
              onBlur={formik.handleBlur}
              label="Mô tả"
              col={12}
            />
          </DialogFormField>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default DialogsEdit;
