import React, { useEffect, useState } from 'react';
import HANOI_CENTER from '@contants/la_center';
import { MapContainer, Marker, Popup, TileLayer, SVGOverlay } from 'react-leaflet';
import { useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import { CircularProgress } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default function DashboardLeafletMap() {
  const styles = {
    main: {
      width: '100%',
      height: '100%'
    },
    inputText: {
      padding: '10px',
      color: 'red'
    },
    titleText: {
      fontSize: 15,
      fontWeight: 'bold'
    }
  };
  let { data, isLoading, refetch, error, isSuccess } = useQuery(['admin-all-depots'], () =>
    rootApi.post('/depots/search?page=0&size=9999')
  );
  const [places, setPlaces] = useState([]);
  const position = [8.1386, 5.1026];
  const zoomLevel = 6;
  const boundHoangSa = [
    [16.90293702652209, 100.16453337003615],
    [15.913539832334903, 123.59803480229532]
  ];
  const boundTruongSa = [
    [11.734346361568868, 110.83372991513322],
    [9.734346361568868, 120.83372991513322]
  ];

  useEffect(() => {
    const dataMap = data?.data?.data?.map((dp) => {
      return {
        id: dp?.id || '',
        name: dp?.depot_name || '',
        code: dp?.depot_code || '',
        address: dp?.depot_address || '',
        lat: dp?.latitude || '',
        lng: dp?.longitude || '',
        partner_id: dp?.partner_id || '',
        show: false,
        depot: true
      };
    });
    setPlaces(dataMap);
  }, [data]);

  function genIcon(partner_id) {
    let iconUrl = '/assets/images/icon/location_evc.png';
    if (partner_id === 1) {
      iconUrl = '/assets/images/icon/location_evc.png';
    } else if (partner_id === 2) {
      iconUrl = '/assets/images/icon/location_evc.png';
    }
    return new Leaflet.Icon({
      iconUrl: iconUrl,
      iconAnchor: [5, 45],
      popupAnchor: [10, -44],
      iconSize: new Leaflet.Point(45, 45)
    });
  }

  const vinfastIcon = genIcon(2);
  const everevIcon = genIcon(1);
  const defaultIcon = genIcon(0);

  const getIcon = (partner_id) => {
    if (partner_id === 1) {
      console.log('icon everev');
      return everevIcon;
    } else if (partner_id === 2) return vinfastIcon;
    else return defaultIcon;
  };

  return (
    <>
      <MapContainer
        style={styles.main}
        zoom={zoomLevel}
        center={HANOI_CENTER}
        scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <SVGOverlay attributes={{ stroke: 'red' }} bounds={boundHoangSa}>
          <text x="50%" y="50%" stroke="white" style={styles.titleText}>
            {'Hoàng Sa(Việt Nam)'}
          </text>
        </SVGOverlay>
        <SVGOverlay attributes={{ stroke: 'red' }} bounds={boundTruongSa}>
          <text x="50%" y="50%" stroke="white" style={styles.titleText}>
            {'Trường Sa(Việt Nam)'}
          </text>
        </SVGOverlay>
        {places?.map((place) => (
          <Marker position={[place.lat, place.lng]} key={place.id} icon={getIcon(place.partner_id)}>
            <Popup>
              <b>Tên trạm:</b> {place.name}
              <br />
              {/*<b>Mã trạm:</b> {place.code}*/}
              {/*<br />*/}
              <p>
                <b>Địa chỉ:</b> {place.address}
              </p>
              <b>Lat:</b> {place.lat}
              <br />
              <b>Lng:</b> {place.lng}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      {isLoading && <CircularProgress size={20} />}
    </>
  );
}
