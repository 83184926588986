import React from 'react';
import { useTheme } from '@mui/material';
import { Small } from '@components/Typography';

export default function StatusLabel({ label, color, multiple }) {
  const { palette } = useTheme();

  const getColorText = () => {
    if (color === 'success') {
      return palette.success.main;
    }
    if (color === 'warning') {
      return palette.warning.main;
    }
    if (color === 'primary') {
      return palette.primary.main;
    }
    return color;
  };

  const styleSingle = {
    padding: '5px 15px',
    borderRadius: '5px',
    color: '#fff'
  };

  const styleMultipleLine = {
    padding: '5px 15px',
    borderRadius: '10px',
    color: '#fff',
    marginLeft: '10px'
  };

  return (
    <Small bgcolor={getColorText} style={multiple ? styleMultipleLine : styleSingle}>
      {label}
    </Small>
  );
}
