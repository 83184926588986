import React from 'react';
import Loadable from '@components/Loadable';
import { lazy } from 'react';

const Transactions = Loadable(lazy(() => import('./Transactions')));
const WalletTransactionHistory = Loadable(lazy(() => import('./WalletTransactionHistory')));
const ManageBills = Loadable(lazy(() => import('./ManageBills')));

const accountingRouters = [
  { path: '/accounting/transactions', element: <Transactions /> },
  { path: '/accounting/walletTransactionHistory', element: <WalletTransactionHistory /> },
  { path: '/accounting/manageBills', element: <ManageBills /> }
];

export default accountingRouters;
