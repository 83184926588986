import rootApi from '@utils/baseAPI/WrapperApi';

export const signIn = (username, password) => {
  return rootApi.post('/users/sign-in', {
    username: username,
    password: password
  });
};

export const getUserInfo = (token) => {
  return rootApi.get('/users/me');
};

export const resetPassword = (username) => {
  return rootApi.post('/users/reset/password', { username: username });
};

export const Registered = (data) => {
  return rootApi.post('/users/register', data);
};

export const deleteData = (username, password, reason, description) => {
  return rootApi.post('/users/remove', {
    username: username,
    password: password,
    reason: reason,
    description: description
  });
};
