import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const StyledTableHead = withStyles(() => ({}))(TableHead);

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5),
    backgroundColor: '#F4F4F5'
  }
}))(TableCell);

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    onRequestSort,
    headerGroups,
    enableChecked = false
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <StyledTableHead>
      {headerGroups.map((headerGroup, index) => (
        <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
          {enableChecked ? (
            <StyledTableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts'
                }}
              />
            </StyledTableCell>
          ) : (
            <></>
          )}

          {headerGroup.headers.map((column, indexColum) => {
            return (
              <StyledTableCell
                key={indexColum}
                {...column.getHeaderProps({
                  style: {
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth
                  },
                  ...column.getSortByToggleProps()
                })}>
                <Stack
                  direction="row"
                  justifyContent={column.textAlign === 'right' ? 'flex-end' : 'flex-start'}>
                  <Typography variant="subtitle2">{column.render('Header')}</Typography>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )
                  ) : (
                    ''
                  )}
                </Stack>
              </StyledTableCell>
            );
          })}
        </TableRow>
      ))}
    </StyledTableHead>
  );
};

export default EnhancedTableHead;
