import React from 'react';
import { styled } from '@mui/system';

const Container = styled('div')(({ theme }) => ({
  margin: '15px',
  [theme.breakpoints.down('sm')]: {
    margin: '10px'
  },
  '& .breadcrumb': {
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px'
    }
  }
}));

const ContainerBody = ({ children }) => {
  return <Container>{children}</Container>;
};

export default ContainerBody;
