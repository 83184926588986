import React from 'react';
import { compose } from 'redux';
import { usePagination, withPaginate } from '@contexts/Paginate/PaginateContext';
import Box from '@mui/material/Box';
import DataTable from '@components/DataTable';
import { useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import moment from 'moment';
import ActViewDetail from './ActionTable/ActViewDetail';
import DialogsViewDetail from './Dialogs/DialogsViewDetail';
import FilterTable from './FilterTable';
import DialogsEdit from './Dialogs/DialogsEdit';
import ActEditCustomer from './ActionTable/ActEditCustomer';
import ActDeleteCustomer from './ActionTable/ActDeleteCustomer';
import DialogsDelete from './Dialogs/DialogsDelete';
import { addCommas, convertObjectToUrlParam } from '@utils/urls';
import RequestButton from './BtnRequest';
import { formatDateToString } from '@components/helper';
import { Card } from '@material-ui/core';
import { Link } from '@mui/material';
import { Grid } from '@mui/material';
const getColumns = ({ refetch, setOpen, setOpenView, setOpenDelete, setTag }) => {
  return [
    { accessor: 'filename', Header: 'Tên file' },
    { accessor: 'description', Header: 'Mô tả' },
    { accessor: 'vendor', Header: 'Nhà cung cấp' },
    { accessor: 'version', Header: 'Phiên bản' },
    {
      accessor: (d) => {
        return (
          <Link
            style={{ textAlign: 'left' }}
            component="button"
            variant="body2"
            onClick={() => {
              window.open(d?.url, '_blank');
            }}>
            Xem file
          </Link>
        );
      },
      Header: `Đường dẫn`
    },
    {
      accessor: (d) => {
        return d.created_timestamp != null
          ? formatDateToString(d.created_timestamp * 1000)
          : 'None';
      },
      Header: 'Thời gian tạo'
    },
    {
      Header: 'Hành động',
      width: '120px',
      accessor: (d) => {
        return (
          <>
            <ActViewDetail tag={d} setTag={setTag} setOpenView={setOpenView} />
            <ActEditCustomer tag={d} setTag={setTag} setOpen={setOpen} />
            <ActDeleteCustomer tag={d} setTag={setTag} setOpenDelete={setOpenDelete} />
          </>
        );
      }
    }
  ];
};

const ListCustomerTag = () => {
  const [open, setOpen] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [tag, setTag] = React.useState(null);
  const [dataFilter, setDataFilter] = React.useState();
  const [flagSearch, setFlagSearch] = React.useState(false);
  const [qStrs, setQStrs] = React.useState('1=1');
  const { page, pageSize } = usePagination();
  const currentPage = parseInt(page - 1, 10);
  let { data, isLoading, refetch, error, isSuccess } = useQuery(
    ['admin-get-all-users', page, pageSize],
    () => rootApi.get(`/files/search?page=${currentPage}&size=${pageSize}&${qStrs}`)
  );

  const onFilterChange = (dataFilter) => {
    setQStrs(convertObjectToUrlParam(dataFilter));
  };

  React.useEffect(() => {
    refetch();
  }, [flagSearch]);
  return (
    <>
      <DialogsViewDetail
        openView={openView}
        setOpenView={setOpenView}
        tag={tag}
        refetch={refetch}
      />
      <DialogsEdit open={open} setOpen={setOpen} tag={tag} refetch={refetch} />
      <DialogsDelete
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        tag={tag}
        refetch={refetch}
      />
      <Card>
        <Box width="100%" overflow="auto" paddingX={2}>
          <Grid container justifyContent="space-between">
            <Grid md={10} sm={10} item>
              {/* <FilterTable
                dataFilter={dataFilter}
                onChange={onFilterChange}
                flagSearch={flagSearch}
                setFlagSearch={setFlagSearch}
                customers={data?.data.data}
                refetch={refetch}
              /> */}
              <h2 style={{ fontSize: '20px', fontWeight: 700 }}>Quản lý file</h2>
            </Grid>
            <Grid item sx={{ mt: 2 }}>
              <RequestButton refetch={refetch} />
            </Grid>
          </Grid>

          <DataTable
            style={{ marginTop: '10px' }}
            columns={getColumns({
              refetch,
              setOpen,
              setOpenView,
              setOpenDelete,
              setTag
            })}
            data={data?.data.data || []}
            total={data?.data.page.total_elements || 0}
            loading={isLoading}
            error={error}
            isSuccess={isSuccess}
            tableName="Quản lý khách hàng"
          />
        </Box>
      </Card>
    </>
  );
};
export default compose(withPaginate)(ListCustomerTag);
