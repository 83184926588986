import React, { useState } from 'react';
import { compose } from 'redux';
import { Breadcrumb } from '@components/index';
import { withPaginate } from '@contexts/Paginate/PaginateContext';
import ContainerBody from '@components/ContainerBody';
import ListDepot from './components/ListDepot';
import { useTranslation } from 'react-i18next';
const ManageDepot = () => {
  const { t } = useTranslation(['admin']);
  return (
    <ContainerBody>
      <div className="breadcrumb">
        <Breadcrumb
          homePath={'/account/dashboard'}
          routeSegments={[{ name: 'Trang chủ', path: '/dashboard' }, { name: `Cấu hình trạm sạc` }]}
        />
      </div>
      <ListDepot />
    </ContainerBody>
  );
};
export default compose(withPaginate)(ManageDepot);
