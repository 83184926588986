import React, { useState } from 'react';
import { compose } from 'redux';
import { usePagination, withPaginate } from '@contexts/Paginate/PaginateContext';
import DataTable from '@components/DataTable';
import { Box, Card, Link, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import { convertObjectToUrlParam } from '@utils/urls';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import StatusLabel from '@page/Common/StatusLabel';
import DialogBindTariff from './Dialogs/DialogBindTariff';
import DialogUnbindTariff from './Dialogs/DialogUnBindTariff';
import FilterTable from './FilterTable';
import moment from 'moment/moment';
import Chip from '@mui/material/Chip';

const ListDepot = () => {
  const { t } = useTranslation(['admin']);
  const navigate = useNavigate();
  const [openDialogBlind, setOpenDialogBlind] = useState(false);
  const [openDialogUnBlind, setOpenDialogUnBlind] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handleOpenBlindTariff = (d) => {
    setOpenDialogBlind(true);
    setSelectedRow(d);
  };
  const handleOpenUnBlindTariff = (d) => {
    setOpenDialogUnBlind(true);
    setSelectedRow(d);
  };
  const getColumns = () => {
    return [
      {
        accessor: 'depot_name',
        Header: `${t('depot.depot_name')}`
      },
      { accessor: 'depot_code', Header: `${t('depot.depot_code')}` },
      {
        accessor: (d) => {
          return 'Công Cộng';
        },
        Header: `${t('depot.depot_type')}`
      },
      { accessor: 'number_connector_available', Header: `${t('depot.depot_connector')}` },
      { accessor: 'partner_response.name', Header: `${t('depot.partner')}` },
      // { accessor: 'latitude', Header: `${t('depot.latitude')}` },
      // { accessor: 'longitude', Header: `${t('depot.longitude')}` },
      { accessor: 'depot_pic', Header: `${t('depot.depot_pic')}` },
      {
        accessor: 'depot_address',
        Header: `${t('depot.address')}`
      },
      {
        accessor: (d) => {
          return (
            <>
              {d?.has_car_charge ? (
                <StatusLabel label="Có" color="success" />
              ) : (
                <StatusLabel label="Không" color="warning" />
              )}
            </>
          );
        },
        Header: `${t('depot.depot_has_car')}`
      },
      {
        accessor: (d) => {
          return d.tariff_active?.name;
        },
        Header: `${t('depot.tariff_active')}`
      },
      {
        accessor: (d) => {
          let tariff_blinds_name = d?.tariff_binds
            ?.map((item, index) => `${index + 1}. ${item?.name}`)
            ?.toString();
          tariff_blinds_name = tariff_blinds_name?.replace(/,/g, '<hr>');
          return <div dangerouslySetInnerHTML={{ __html: tariff_blinds_name }} />;
        },
        Header: `${t('depot.tariff_blinds')}`
      },
      {
        accessor: (d) => {
          return (
            <>
              {d?.tariff_binds?.length > 0 ? (
                <Link
                  style={{ textAlign: 'center', color: '#E20120' }}
                  component="button"
                  variant="body2"
                  onClick={() => {
                    handleOpenUnBlindTariff(d);
                  }}>
                  <Chip
                    label="Gỡ giá sạc"
                    size="small"
                    sx={{
                      textAlign: 'center',
                      backgroundColor: '#FA5050',
                      fontSize: '11px',
                      fontWeight: 700,
                      cursor: 'pointer',
                      color: '#FFFF'
                    }}
                  />
                </Link>
              ) : (
                <Link
                  style={{ textAlign: 'center', color: '#489F3E', cursor: 'pointer' }}
                  component="button"
                  variant="body2"
                  onClick={() => {
                    handleOpenBlindTariff(d);
                  }}>
                  <Chip
                    label="Gán giá sạc"
                    size="small"
                    variant="outlined"
                    sx={{
                      textAlign: 'center',
                      // backgroundColor: '#2D9764',
                      fontSize: '11px',
                      fontWeight: 700,
                      cursor: 'pointer',
                      color: '#2D9764'
                    }}
                  />
                </Link>
              )}
            </>
          );
        },
        Header: `Hành động`
      }
    ];
  };
  const [open, setOpen] = React.useState(false);
  const [dataFilter, setDataFilter] = React.useState();
  const [flagSearch, setFlagSearch] = React.useState(false);
  const [qStrs, setQStrs] = React.useState('1=1');
  const { page, pageSize } = usePagination();
  const currentPage = parseInt(page - 1, 10);

  let { data, isLoading, refetch, error, isSuccess } = useQuery(
    ['admin-all-depots', page, pageSize],
    () => rootApi.get(`/depots?page=${currentPage}&size=${pageSize}&${qStrs}`)
  );

  React.useEffect(() => {
    setQStrs(convertObjectToUrlParam(dataFilter));
  }, [dataFilter]);

  React.useEffect(() => {
    refetch();
  }, [flagSearch]);

  return (
    <>
      <Card>
        <Box width="100%" overflow="auto" paddingX={2}>
          <h2 style={{ fontSize: '20px', fontWeight: 700 }}>Quản lý cấu hình giá cho trạm</h2>
          <FilterTable
            dataFilter={dataFilter}
            onChange={setDataFilter}
            flagSearch={flagSearch}
            setFlagSearch={setFlagSearch}
            depots={data?.data.data}
          />
          <DataTable
            style={{ marginTop: '10px' }}
            columns={getColumns({
              refetch,
              setOpen
            })}
            data={data?.data.data || []}
            total={data?.data?.page?.total_elements || 0}
            loading={isLoading}
            error={error}
            isSuccess={isSuccess}
            tableName="Quản lý trạm sạc"
          />
          <DialogBindTariff
            open={openDialogBlind}
            setOpen={setOpenDialogBlind}
            refetch={refetch}
            depot={selectedRow}
          />
          <DialogUnbindTariff
            open={openDialogUnBlind}
            setOpen={setOpenDialogUnBlind}
            refetch={refetch}
            depot={selectedRow}
          />
        </Box>
      </Card>
    </>
  );
};
export default compose(withPaginate)(ListDepot);
