import React from 'react';
import { Avatar } from '@material-ui/core';
import { Box, Divider, Grid, Typography, Card, Stack, CardContent } from '@mui/material';
import { grey } from '@mui/material/colors';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import { formatCurrency, getDuration } from '@components/helper';
import moment from 'moment';

export const ItemCharging = ({ item }) => {
  return (
    <Box sx={{ border: 0.5, borderColor: '#E4E4E7', borderRadius: 2 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        padding={2}>
        <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Avatar style={{ backgroundColor: '#EAF5EF', height: 48, width: 48 }}>
            <ElectricalServicesIcon sx={{ height: 24, width: 24, color: '#2D9764' }} />
          </Avatar>
          <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
            <Typography
              variant="span"
              component="span"
              style={{ fontSize: '16px', fontWeight: 500 }}>
              {`${item?.power ?? 0} Kwh`}
            </Typography>
            <Typography
              variant="span"
              component="span"
              style={{ fontSize: '14px', fontWeight: 400, color: '#52525B' }}>
              {item?.id_tag
                ? `${item?.id_tag} · ${getDuration(
                    item?.start_timestamp * 1000,
                    item?.stop_timestamp * 1000
                  )}`
                : `${getDuration(item?.start_timestamp * 1000, item?.stop_timestamp * 1000)}`}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="column" justifyContent="flex-end" alignItems="flex-end">
          <Typography
            variant="span"
            component="span"
            style={{ fontSize: '16px', fontWeight: 500, color: '#FA5050' }}>
            - {formatCurrency(item?.amount || 0)} EVC
          </Typography>
          <Typography variant="span" component="span" style={{ fontSize: '14px', fontWeight: 400 }}>
            {moment(item?.created_time * 1000).format('DD-MM-YYYY HH:mm')}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
