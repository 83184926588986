import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from '@mui/material';
import rootApi from '@utils/baseAPI/WrapperApi';
import { useMutation } from 'react-query';
import { showError, showSuccess } from '@components/notification';

const DialogUnbindTariff = (props) => {
  const { depot, open, setOpen, refetch } = props;
  const { mutateAsync, isLoading } = useMutation('unbind-tariff', () => {
    console.log('AXXXXX');
    console.log(depot?.tariff_binds[0].id);
    return rootApi.post(`/tariff-depots/unbind`, {
      depot_codes: [depot?.depot_code],
      tariff_id: depot?.tariff_binds[0].id
    });
  });

  const handleClose = () => {
    refetch();
    setOpen(false);
  };

  const handleDelete = () => {
    mutateAsync()
      .then(() => {
        handleClose();
        showSuccess({ message: 'Gõ trụ khỏi trạm thành công' });
      })
      .catch((error) => {
        showError(error || 'Vui lòng liên hệ admin để được hỗ trợ');
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Thông báo</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ display: 'flex' }}>
          Bạn có chắc chắn muốn gỡ giá khỏi trạm không?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} variant="contained" color="primary" disabled={isLoading}>
          {isLoading && <CircularProgress size={10} />} Đồng ý
        </Button>
        <Button onClick={handleClose} autoFocus variant="contained" color="inherit">
          Hủy
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogUnbindTariff;
