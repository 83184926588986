import moment from 'moment';

export const formatDateToString = (date) =>
  date ? moment(date).format('DD-MM-YYYY HH:mm').toString() : '';

export function formatDate(dateObj, pattern = '-') {
  const d = new Date(dateObj);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join(pattern);
}

export function isNullOrEmpty(value) {
  return value === undefined || value === null || value === '';
}

export function formatCurrency(money, digits) {
  if (isNullOrEmpty(money)) {
    return '0';
  }
  let format = '$1.';
  return `${digits ? money : Math.trunc(+money)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, format);
}

export const convertLocaltimeToUTC = (date, hour) => {
  let result = null;
  try {
    if (date) {
      const localDateTimeMoment = moment(
        `${moment(new Date(date)).format('YYYY-MM-DD')} ${hour}`,
        'YYYY-MM-DD HH:mm:ss.SSS'
      );

      const utcDateTimeMoment = localDateTimeMoment.utc();

      result = utcDateTimeMoment.toISOString();
    }
  } catch (e) {
    console.log('Exception at convertLocaltimeToUTC');
    console.log(e);
  }

  return result;
};

export const generateDateTimeArray = (intervalInMinutes = 60) => {
  const currentDate = new Date();
  currentDate.setUTCHours(0, 0, 0, 0);

  const lastDayMidnight = new Date(currentDate.getTime() - 86400000);
  const endOfToday = new Date();
  endOfToday.setUTCHours(23, 59, 59, 999);

  const dateTimeArray = [];

  for (
    let date = lastDayMidnight;
    date <= endOfToday;
    date.setUTCMinutes(date.getUTCMinutes() + intervalInMinutes)
  ) {
    dateTimeArray.push(date.toISOString());
  }

  return dateTimeArray;
};

export const getPast30Days = () => {
  var result = [];
  var today = new Date();
  for (var i = 0; i <= 30; i++) {
    var pastDate = new Date(today);
    pastDate.setDate(today.getDate() - i);
    var day = pastDate.getDate().toString().padStart(2, '0');
    var month = (pastDate.getMonth() + 1).toString().padStart(2, '0');
    var year = pastDate.getFullYear();
    result.push(day + '/' + month);
  }
  return result?.reverse();
};

export const getDuration = (start_timestamp, stop_timestamp) => {
  if (start_timestamp == null || stop_timestamp == null) {
    return 0;
  }
  const start = moment(start_timestamp).format('DD-MM-YYYY HH:mm').toString();
  const stop = moment(stop_timestamp).format('DD-MM-YYYY HH:mm').toString();
  const ms = moment(stop, 'DD-MM-YYYY HH:mm:ss').diff(moment(start, 'DD-MM-YYYY HH:mm:ss'));
  const duration = moment.duration(ms);
  return Math.floor(duration.asHours()) + moment.utc(ms).format(':mm:ss');
};
