import React from 'react';
import { Box, Typography } from '@mui/material';

const ItemRow = ({ title, value, color = 'rgba(52, 49, 76, 1)' }) => {
  return (
    <Box
      py={1}
      sx={{
        display: 'inline-flex',
        width: '100%',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
      }}>
      <Typography variant="subtitle2" style={{ width: '135px', color: 'rgba(52, 49, 76, 1)' }}>
        {title}
      </Typography>
      <Typography variant="subtitle2" color={color} style={{ fontWeight: 'normal' }}>
        {value}
      </Typography>
    </Box>
  );
};

export default ItemRow;
