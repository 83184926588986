import React, { useState } from 'react';
import SelectField from '@components/FormControls/SelectField';
import { useFormik } from 'formik';
import rootApi from '@utils/baseAPI/WrapperApi';
import { useMutation, useQuery } from 'react-query';
import DialogFormField from '@components/FormControls/DialogFormField';
import { showError, showSuccess } from '@components/notification';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as Yup from 'yup';
import { apiBlindTariff } from '@services/depot.services';

const DialogBindTariff = (props) => {
  const { depot, open, setOpen, refetch } = props;
  const dataTariff = useQuery(['admin-get-tariff'], () =>
    rootApi.get(`tariffs/search?page=0&size=9999999`)
  );

  const tariffs = dataTariff?.data?.data.data || [];
  const tariffOptions = tariffs?.map((item) => ({
    label: item?.name,
    value: item?.id
  }));
  const handleChangeTariff = (event, selectedValue) => {
    formik.setFieldValue('tariff_id', selectedValue);
  };

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      tariff_id: tariffOptions[0]
    },
    validationSchema: Yup.object({
      tariff_id: Yup.array().required().label('Cấu hình giá').nullable()
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const payload = {
          depot_code: depot?.depot_code,
          tariff_ids: values?.tariff_id?.map((item) => item?.value)
        };
        await apiBlindTariff(payload);
        showSuccess({ message: 'Gắn giá trạm thành công' });
        handleClose();
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
  });

  const handleClose = () => {
    formik.resetForm();
    refetch();
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Gán giá trạm sạc</DialogTitle>
        <DialogContent>
          <DialogFormField
            onCloseDialog={handleClose}
            isLoading={isLoading}
            formik={formik}
            icon="save"
            actionName="Lưu">
            <SelectField
              multiple
              formik={formik}
              options={tariffOptions}
              value={formik.values.tariff_id}
              getOptionLabel
              required
              onChange={handleChangeTariff}
              name="tariff_id"
              label="Chọn cấu hình giá"
              col={12}
            />
          </DialogFormField>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogBindTariff;
