import React from 'react';
import Loadable from '@components/Loadable';
import { lazy } from 'react';
import DashboardLeafletMap from '@page/Dashboard/DashboardLeafletMap';

const Profile = Loadable(lazy(() => import('./User/Profile')));
const Wallet = Loadable(lazy(() => import('./User/Wallet')));

const customerRouters = [
  { path: '/account/profile', element: <Profile /> },
  { path: '/account/wallet', element: <Wallet /> },
  { path: '/account/maps', element: <DashboardLeafletMap /> }
];

export default customerRouters;
