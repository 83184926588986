import React from 'react';

import { DashboardLayout } from './index';
import SuspenseComponent from '@components/Suspense';
import useSettings from '@hooks/useSettings';

const DashboardLayoutComponent = (props) => {
  const { settings } = useSettings();
  const Layout = DashboardLayout[settings.activeLayout];

  return (
    <SuspenseComponent>
      <Layout {...props} />
    </SuspenseComponent>
  );
};

export default DashboardLayoutComponent;
