import React from 'react';
import { Grid, TextField } from '@mui/material';
import SelectBox from '@components/SelectBox';
import { useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import { Box } from '@mui/system';
import { Clear, Search } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { usePagination } from '@contexts/Paginate/PaginateContext';
const FilterTable = ({ dataFilter, onChange, setFlagSearch, flagSearch, depots }) => {
  const { t } = useTranslation(['admin']);
  const { page, pageSize, resetPaginate } = usePagination();
  const getPartnerOptions = () => {
    const { data: partners } = useQuery(['get-partners-list'], () =>
      rootApi.get('/admin/partners/search')
    );
    const listPartner = partners?.data.data || [];
    const partner_ops = listPartner.map((item) => {
      return {
        label: `${item?.name}`,
        value: item?.id
      };
    });
    return partner_ops;
  };
  const partner_options = getPartnerOptions();

  const handleSearchTable = () => {
    if (page != 1 || pageSize != 10) {
      resetPaginate();
      return;
    }
    setFlagSearch(!flagSearch);
  };

  const onChangeDepotCode = (text) => {
    onChange({
      ...dataFilter,
      depot_code: text
    });
  };
  const onChangePartner = (_, par) => {
    if (par === null && dataFilter !== undefined) {
      const { partner_id, ...remain } = dataFilter;
      dataFilter = remain;
      onChange(dataFilter);
    }
    if (par?.value !== undefined) {
      onChange({
        ...dataFilter,
        partner_id: par?.value
      });
    }
  };
  return (
    <Box width="100%" overflow="auto">
      <Grid container spacing={1}>
        <Grid item md={4} sm={4}>
          <SelectBox
            onChange={onChangePartner}
            options={partner_options}
            name="partner_id"
            label="Chọn công ty - đối tác"
            size="small"
            col={12}
          />
        </Grid>
        <Grid item md={4} sm={4} sx={{ mt: 1 }}>
          <TextField
            fullWidth
            onChange={(e) => onChangeDepotCode(e.target.value)}
            size="small"
            type="text"
            name="depot_code"
            label={t('depot.depot_code')}
            variant="outlined"
          />
        </Grid>
        <Grid item md={4} sm={4} sx={{ mt: 1 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Search />}
            sx={{
              color: '#FFFFFF',
              fontSize: '14px',
              fontWeight: 600,
              bgcolor: '#2D9764',
              '&:hover': {
                bgcolor: '#2D9764'
              }
            }}
            onClick={handleSearchTable}>
            Tìm kiếm
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterTable;
