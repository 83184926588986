import React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Box, Stack, Typography } from '@mui/material';
import Loading from '@components/Loading';
export const LinearProcessBarCustom = ({ label, value, loading, value_1, value_2, color }) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => {
    return {
      height: 15,
      borderRadius: 5,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: color
      }
    };
  });

  return (
    <>
      {loading ? (
        <Loading></Loading>
      ) : (
        <Stack direction={'column'}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            mb={1}>
            <Typography variant="body2" color="text.secondary">
              {label}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {`${value} % (${value_2}/${value_1})`}
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }}>
            <BorderLinearProgress variant="determinate" value={value} />
          </Box>
        </Stack>
      )}
    </>
  );
};
