import axios from 'axios';
import authHeader from './AuthHeader';

import { get, isEmpty } from 'lodash';
import { URL_API } from '@configs/';
import { loadFromLocalStorageObjectFromBase64, saveToLocalStorage } from '@databases/localStorage';
import { contantAuthentication } from '@contants/';
import { showError } from '@components/notification';

const rootApi = axios.create({
  baseURL: URL_API,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
  timeout: 30000
});

rootApi.interceptors.request.use(
  function (config) {
    let user = loadFromLocalStorageObjectFromBase64(contantAuthentication.DATA_AUTH);
    if (!isEmpty(user)) {
      user = JSON.parse(user);
    }
    config.headers.Authorization = user?.access_token ? `Bearer ${user?.access_token}` : '';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

rootApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    showError(error.response?.data.status.message || 'Vui lòng liên hệ admin để được hỗ trợ');
    return Promise.reject(error);
  }
);

export default rootApi;
