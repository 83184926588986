import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withPaginate } from '@contexts/Paginate/PaginateContext';
import { Box, Divider, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import rootApi from '@utils/baseAPI/WrapperApi';
import { useMutation, useQuery } from 'react-query';
import Page from '@components/LayoutPage';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Personal } from './tabs/personal';
import { Charging } from './tabs/charging';
import { Deposit } from './tabs/deposit';
import Button from '@mui/material/Button';
import { downloadBlobFile } from '@utils/utils';
import { showError, showSuccess } from '@components/notification';
import { URL_API } from '@configs/index';
import dayjs from 'dayjs';
import { Clear, Search, FileDownload } from '@mui/icons-material';
import { convertLocaltimeToUTC } from '@components/helper';

const DetailCustomer = () => {
  const { id } = useParams(); // Read values passed on state
  const navigate = useNavigate();
  const { t } = useTranslation(['admin']);
  const [loading, setLoading] = useState(false);

  let { data, isLoading, refetch } = useQuery(['admin-detail-customer'], () =>
    rootApi.get(`/admin/users/${id}`)
  );
  const customer = data?.data.data || {};

  const [valueTab, setValueTab] = useState('personal');
  const handleChangeTab = (e, value) => {
    setValueTab(value);
  };
  const handleDownloadFile = async () => {
    try {
      setLoading(true);
      const sixMonthBeforeDate = new Date();
      sixMonthBeforeDate.setMonth(new Date().getMonth() - 6);
      await downloadBlobFile(
        `${URL_API}/admin/payments/history/transaction/export`,
        `Báo cáo giao dịch KH ${customer?.full_name ?? ''} 6 tháng gần nhất.xlsx`,
        {
          user_id: id,
          from_date: convertLocaltimeToUTC(sixMonthBeforeDate, '00:00:00.000'),
          to_date: convertLocaltimeToUTC(new Date(), '23:59:59.999')
        }
      );
      showSuccess({ message: 'Xuất báo cáo thành công!' });
    } catch (e) {
      showError({ message: 'Không tìm thấy báo cáo!' });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Page loading={false}>
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          justifyContent="space-between"
          alignItems="flex-start"
          mb={1}>
          <h2 style={{ fontSize: '20px', fontWeight: 700 }}>Chi tiết khách hàng</h2>
          <Button
            variant="contained"
            sx={{
              color: '#FFFFFF',
              fontSize: '14px',
              fontWeight: 600,
              bgcolor: '#2D9764',
              '&:hover': {
                bgcolor: '#2D9764'
              }
            }}
            loading={loading}
            startIcon={<FileDownload />}
            onClick={handleDownloadFile}>
            Xuất lịch sử giao dịch
          </Button>
        </Stack>
        <TabContext value={valueTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: '#F4F4F5' }}>
            <TabList
              onChange={handleChangeTab}
              aria-label="lab API tabs example"
              sx={{
                '& .Mui-selected': {
                  color: '#2D9764',
                  bgcolor: '#FFFF'
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: '#2D9764'
                },
                '& .MuiTab-root': {
                  textTransform: 'none',
                  borderRadius: '40px'
                }
              }}>
              <Tab label="Thông tin khách hàng" value="personal" />
              <Tab label="Phiên sạc" value="charging" />
              <Tab label="Nạp tiền" value="deposit" />
            </TabList>
          </Box>
          <TabPanel value="personal">
            <Personal customer={customer} />
          </TabPanel>
          <TabPanel value="charging">
            <Charging customer={customer} />
          </TabPanel>
          <TabPanel value="deposit">
            <Deposit customer={customer} />
          </TabPanel>
        </TabContext>
      </Page>
    </>
  );
};

export default compose(withPaginate)(DetailCustomer);
