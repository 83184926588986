import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import moment from 'moment';
import ItemRow from '@components/ActionsCommon/ItemRows';
import { Link } from '@mui/material';
import { formatDateToString } from '@components/helper';
const DialogsViewDetail = (props) => {
  const { tag, openView, setOpenView, refetch } = props;
  const handleClose = () => {
    setOpenView(false);
  };
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openView}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Chi tiết file</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ItemRow title="Tên file:" value={tag?.filename} />
            <ItemRow title="Mô tả:" value={tag?.description} />
            <ItemRow title="Nhà cung cấp:" value={tag?.vendor} />
            <ItemRow title="Phiên bản:" value={tag?.version} />
            <ItemRow
              title="Đường dẫn"
              value={
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    window.open(tag?.url, '_blank');
                  }}>
                  Xem file
                </Link>
              }
            />
            <ItemRow
              title="Thời gian tạo:"
              value={
                tag?.created_timestamp ? formatDateToString(tag?.created_timestamp * 1000) : ''
              }
            />
            <ItemRow
              title="Lần cập nhật cuối:"
              value={
                tag?.last_update_timestamp
                  ? moment(tag?.last_update_timestamp).format('DD-MM-YYYY HH:mm')
                  : ''
              }
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogsViewDetail;
