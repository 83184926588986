import React, { useEffect } from 'react';

import { Icon, Grid } from '@mui/material';
import { Span } from '@components/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const FormField = ({
  onCloseDialog,
  children,
  formik,
  isCreating,
  isSuccess,
  actionName,
  icon = 'add-circle-outline'
}) => {
  // useEffect(() => {
  //   if (isSuccess) {
  //     formik?.resetForm();
  //   }
  // }, [isSuccess]);

  const onClose = () => {
    formik?.resetForm();
    onCloseDialog();
  };

  return (
    <form onSubmit={formik?.handleSubmit}>
      <Grid container>{children}</Grid>
      <Box py={2} sx={{ display: 'float', float: 'right' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            mr: 1,
            color: '#FFFFFF',
            fontSize: '14px',
            fontWeight: 600,
            bgcolor: '#2D9764',
            '&:hover': {
              bgcolor: '#2D9764'
            }
          }}
          disabled={isCreating}>
          {isCreating && <CircularProgress size={10} />}
          <Icon>{icon}</Icon>
          <Span sx={{ pl: 1, textTransform: 'capitalize' }}>{actionName}</Span>
        </Button>
        <Button onClick={onClose} variant="contained" color="inherit">
          <Icon>cancel</Icon>
          <Span sx={{ pl: 1, textTransform: 'capitalize' }}>Đóng</Span>
        </Button>
      </Box>
    </form>
  );
};

export default FormField;
