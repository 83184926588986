import React from 'react';
import { Icon, IconButton, Tooltip } from '@mui/material';

const ActEditCustomer = ({ tag, setTag, setOpen }) => {
  const handleOpenDialogEdit = () => {
    setOpen(true);
    setTag(tag);
  };
  return (
    <Tooltip title="Cập nhật thông tin file" placement="top">
      <IconButton className="button" aria-label="Sửa thông tin" onClick={handleOpenDialogEdit}>
        <Icon>mode_edit_outline</Icon>
      </IconButton>
    </Tooltip>
  );
};

export default ActEditCustomer;
