import React from 'react';
import { Typography, Grid, IconButton, Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import cardImage from '@assets/images/customer/card.png';
import Stack from '@mui/material/Stack';
import { formatCurrency } from '@components/helper';

export const EVCCard = ({ customer }) => {
  console.log('customer::::', customer);
  const copyCardNumber = () => {
    navigator.clipboard.writeText(customer?.wallet_response?.wallet_id);
  };
  return (
    <Box
      style={{
        width: '100%',
        height: 250,
        borderRadius: 10,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        backgroundImage: `url(${cardImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'inherit',
        padding: 16
      }}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Typography
          variant="h6"
          component="h2"
          style={{ color: 'white', fontSize: '20px', fontWeight: 600 }}>
          Ví EVC
        </Typography>
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" marginTop={0.5}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ color: 'white', fontSize: '14px', fontWeight: 500 }}>
            {customer?.wallet_response?.wallet_id ?? ''}
          </Typography>
          {customer?.wallet_response?.wallet_id && (
            <IconButton
              onClick={copyCardNumber}
              style={{ color: 'white', width: '20px', height: '20px', marginLeft: 8 }}>
              <ContentCopyIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>

      <Stack direction="column" justifyContent="center" alignItems="center" marginTop={8}>
        <Typography
          variant="h6"
          component="h2"
          style={{ color: 'white', fontSize: '44px', fontWeight: 600 }}>
          {formatCurrency(customer?.wallet_response?.amount || 0)}
          <span style={{ fontSize: '14px', fontWeight: 500, marginLeft: 4 }}>EVC</span>
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ color: 'white', fontSize: '14px', fontWeight: 400 }}>
          Số dư khả dụng
        </Typography>
      </Stack>
    </Box>
  );
};
