import React from 'react';
import { Avatar, Card, CardContent, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import Loading from '@components/Loading';

export const CardInfo = ({
  icon,
  title,
  value = 0,
  label_1,
  value_1,
  label_2,
  value_2,
  label_3,
  value_3,
  loading
}) => {
  return (
    <>
      <Card sx={{ minWidth: 100 + '%', flex: 1 }}>
        <CardContent>
          {loading ? (
            <Loading></Loading>
          ) : (
            <>
              <Stack direction={'column'} justifyContent={'space-between'}>
                <div>
                  <Stack direction={'row'}>
                    <Avatar sx={{ bgcolor: grey[100], height: 48, width: 48 }} variant="rounded">
                      {icon}
                    </Avatar>
                    <Stack direction={'column'} marginLeft={2}>
                      <Typography variant="p" component="div" color={'black'} fontSize={'14px'}>
                        {title}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        color={'#2D9764'}
                        fontWeight={700}
                        fontSize={18}>
                        {value}
                      </Typography>
                    </Stack>
                  </Stack>
                </div>
              </Stack>
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                <div>
                  <Stack direction={'column'}>
                    <span>{label_1}</span>
                    <span style={{ marginLeft: 2, color: '#296BCC', fontWeight: 600 }}>
                      {value_1}
                    </span>
                  </Stack>
                </div>
                <div>
                  <Stack direction={'column'}>
                    <span>{label_2}</span>
                    <span style={{ marginLeft: 2, color: '#296BCC', fontWeight: 600 }}>
                      {value_2}
                    </span>
                  </Stack>
                </div>
                {label_3 && (
                  <div>
                    <Stack direction={'column'}>
                      <span>{label_3}</span>
                      <span style={{ marginLeft: 2, color: '#296BCC', fontWeight: 600 }}>
                        {value_3}
                      </span>
                    </Stack>
                  </div>
                )}
              </Stack>
            </>
          )}
        </CardContent>
      </Card>
    </>
  );
};
