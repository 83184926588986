import React from 'react';
import Loadable from '@components/Loadable';
import { lazy } from 'react';
import ManageDepot from './ManageDepot';
import ManageFiles from './ManageFiles';
import DetailCustomer from './ManageCustomer/components/Detail/DetailCustomer';

const Customer = Loadable(lazy(() => import('../AdminPage/ManageCustomer')));
const CustomerTag = Loadable(lazy(() => import('../AdminPage/ManageCustomerTag')));
const UserOperator = Loadable(lazy(() => import('../AdminPage/ManageUserOperator')));
const TariffPlan = Loadable(lazy(() => import('../AdminPage/TariffPlan')));
const Role = Loadable(lazy(() => import('../AdminPage/ManageRole')));
const Transaction = Loadable(lazy(() => import('../AdminPage/ManageTransaction')));
const TrasactionDetail = Loadable(
  lazy(() => import('../AdminPage/ManageTransaction/components/Detail'))
);
const CarInfo = Loadable(lazy(() => import('../AdminPage/ManageCarInfo')));
const adminRouters = [
  {
    path: '/admin/customer',
    element: <Customer />
  },
  {
    path: '/admin/customer/:id',
    element: <DetailCustomer />
  },
  {
    path: '/admin/tag',
    element: <CustomerTag />
  },
  {
    path: '/admin/carInfo',
    element: <CarInfo />
  },
  {
    path: '/admin/user',
    element: <UserOperator />
  },
  {
    path: '/admin/role',
    element: <Role />
  },
  {
    path: 'admin/charging-session',
    element: <Transaction />
  },
  {
    path: 'admin/charging-session/:id/detail',
    element: <TrasactionDetail />
  },
  {
    path: '/admin/tariff',
    element: <TariffPlan />
  },
  {
    path: '/admin/depots',
    element: <ManageDepot />
  },
  {
    path: '/admin/files',
    element: <ManageFiles />
  }
];

export default adminRouters;
