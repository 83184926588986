import { URL_API } from '@configs/index';
import request from '@utils/baseAPI/WrapperApi';

export const signOut = (data) => {
  return request({
    url: `${URL_API}/auth/signout`,
    method: 'GET',
    data
  });
};
