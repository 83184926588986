import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import { showError } from '@components/notification';
const DialogsDelete = (props) => {
  const { tag, openDelete, setOpenDelete, refetch } = props;
  const { mutateAsync, isLoading, isSuccess } = useMutation('delete-user-tag', (data) => {
    return rootApi.delete(`/files/delete/${tag?.id}`);
  });
  const handleClose = () => {
    setOpenDelete(false);
  };
  const handleDelete = () => {
    mutateAsync()
      .then(() => {
        handleClose();
        refetch();
      })
      .catch((e) => {
        return showError('Xoá thất bại!');
      });
    handleClose();
  };
  return (
    <Dialog
      open={openDelete}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Thông báo</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" sx={{ display: 'flex' }}>
          Bạn có muốn xóa file này không?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} variant="contained" color="primary" disabled={isLoading}>
          {isLoading && <CircularProgress size={10} />} Đồng ý
        </Button>
        <Button onClick={handleClose} autoFocus variant="contained" color="inherit">
          Hủy
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogsDelete;
